import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import Notifications from '@kyvg/vue3-notification'

import dataV from '@jiaminghi/data-view'

const app = createApp(App)
app.use(VCalendar, {})
app.use(router)
app.use(Notifications)
app.use(dataV)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
//createApp(App).mount('#app')