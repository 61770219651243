<template>
  <div class="app">
    <router-view />
    <notifications position="top center" width="100%" max="2" />
  </div>
</template>

<style lang="scss">
.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  .vue-notification-group {
    margin-top: 2rem;
    .notification-title {
      font-size: 14pt;
    }
    .notification-content {
      font-size: 12pt;
    }
  }
}
</style>
